* {
  box-sizing: border-box;
}

body {
  font-family: Helvetica, Arial, sans-serif;
}

/* Style the header */
header {
  background-color: #128c7e;
  text-align: center;
  font-size: 20px;
  color: white;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 15px;
  padding-top: 20px;
}

/* Create two columns/boxes that floats next to each other */

article {
  float: left;
  padding: 20px;
  width: 99%;
  background-color: #f1f1f1;
  height: 300px; /* only for demonstration, should be removed */
}

/* Clear floats after the columns */
section::after {
  content: "";
  display: table;
  clear: both;
  width: 99%;
}

/* Style the footer */
footer {
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  color: white;
  /*position: absolute; */
  bottom: 0;
  width: 100%;
  height: 60px; /* Height of the footer */
  background: #273443;
}

input[type="text"] {
  width: 40%;
  padding: 5px 15px;
  margin-top: 6px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 20px;
}

input[type="text"]:focus {
  background-color: #e3e3e3;
  color: Black;
}

* {
  box-sizing: border-box;
}

/* input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
} */

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: left;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

input[type="button"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: left;
}

input[type="button"]:hover {
  background-color: #45a049;
}
.btnopenchat {
  background-color: #4caf50;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 22px;
  cursor: pointer;
  
}

.btnopenchat:hover {
  background-color: #45a049;
}
/* .container {
  border-radius: 5px;
  padding: 20px;
} */

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
  input[type="text"] {
    width: 100% !important;
  }
}
